import {
  type PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import type { WorkOrder } from '@/generated'
import {
  IWorkOrdersContext,
  WorkOrdersContext,
} from '@/components/modals/case-view-modal/components/work-orders/providers/WorkOrdersContext.ts'

interface WorkOrdersProviderProps {
  workOrders: WorkOrder[]
}

export default function WorkOrdersProvider({
  workOrders,
  children,
}: PropsWithChildren<WorkOrdersProviderProps>) {
  const [selected, setSelected] = useState<WorkOrder>(workOrders[0])

  const handleSelect = useCallback<IWorkOrdersContext['onSelect']>(
    woId => {
      const selected = workOrders.find(({ id }) => id === woId)
      if (selected) setSelected(selected)
    },
    [workOrders]
  )

  const value = useMemo(
    () => ({
      workOrders,
      selected,
      onSelect: handleSelect,
    }),
    [workOrders, selected, handleSelect]
  )
  return (
    <WorkOrdersContext.Provider value={value}>
      {children}
    </WorkOrdersContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useWorkOrdersContext = () => {
  return useContext(WorkOrdersContext)
}

import React, {
  ComponentProps,
  MutableRefObject,
  PropsWithChildren,
  useRef,
} from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { Icon } from '@iconify/react/offline'
import iconClose from '@iconify/icons-heroicons/x-mark'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export interface ModalProps extends PropsWithChildren<ComponentProps<'div'>> {
  show: boolean
  initialFocus?: MutableRefObject<HTMLElement | null>
  onClose: () => void
}

interface ModalHeaderProps extends PropsWithChildren<ComponentProps<'div'>> {
  showCloseButton?: boolean
  onClose: () => void
}

type ModalBodyProps = PropsWithChildren<ComponentProps<'div'>>

type ModalFooterProps = PropsWithChildren<ComponentProps<'div'>>

function ModalHeader({
  showCloseButton = true,
  className,
  onClose,
  children,
  ...props
}: Readonly<ModalHeaderProps>) {
  return (
    <DialogTitle
      as="div"
      className="tw-flex tw-items-start tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-gray-200 tw-p-4"
    >
      <h3
        className={twMerge(
          `tw-text-xl tw-font-semibold tw-text-gray-900`,
          className
        )}
        {...props}
      >
        {children}
      </h3>
      {showCloseButton && (
        <button
          type="button"
          className="tw--m-1.5 tw-ml-auto tw-inline-flex tw-items-center tw-rounded-lg tw-border-0 tw-bg-gray-50 tw-p-1.5 tw-text-gray-500 hover:tw-bg-gray-200 focus:tw-ring-2 focus:tw-ring-gray-400"
          onClick={() => {
            onClose()
          }}
        >
          <span className="tw-sr-only">Close</span>
          <Icon icon={iconClose} className="tw-size-5" aria-hidden="true" />
        </button>
      )}
    </DialogTitle>
  )
}

function ModalBody({ className, children, ...props }: ModalBodyProps) {
  return (
    <div className={twMerge(`tw-space-y-6 tw-p-6`, className)} {...props}>
      {children}
    </div>
  )
}

function ModalFooter({ children, className, ...props }: ModalFooterProps) {
  return (
    <div
      className={twMerge(
        `tw-flex tw-items-center tw-space-x-2 tw-border-b-0 tw-border-l-0 tw-border-r-0 tw-border-t tw-border-solid tw-border-gray-200 tw-p-6`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export function Modal({
  show,
  className,
  initialFocus,
  onClose,
  children,
  ...props
}: Readonly<ModalProps>): React.JSX.Element {
  const panelRef = useRef<HTMLDivElement>(null)
  if (show)
    return (
      <Dialog
        open={show}
        initialFocus={initialFocus ?? panelRef}
        onClose={() => {
          onClose()
        }}
      >
        <div className="tw-fixed tw-inset-0 tw-z-overlay tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
        <DialogPanel
          as="div"
          className="tw-fixed tw-inset-0 tw-z-modal tw-overflow-auto"
        >
          <div className="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0">
            <div
              ref={panelRef}
              tabIndex={-1}
              className={twMerge(
                'tw-relative tw-m-12 tw-transform tw-overflow-auto tw-rounded-lg tw-bg-white tw-text-left tw-shadow-xl tw-transition-all',
                className
              )}
              {...props}
            >
              {children}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    )
  return <></>
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

ModalHeader.displayName = 'Modal.Header'
ModalBody.displayName = 'Modal.Body'
ModalFooter.displayName = 'Modal.Footer'

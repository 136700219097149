import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import CommercialAssetDetailsTab from '@/pages/assetdetail/tabComponents/CommercialAssetDetailsTab.tsx'
import ResidentialAssetDetailsTab from '@/pages/assetdetail/tabComponents/ResidentialAssetDetailsTab.tsx'

export default function AssetDetailsTabWrapper() {
  const { isResidentialClient } = useFeatureFlags()
  if (isResidentialClient) {
    return <ResidentialAssetDetailsTab />
  }
  return <CommercialAssetDetailsTab />
}

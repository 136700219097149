import { useAssetCaseQuery } from '@/api/AssetQueries'
import DrawerWithAsyncContent from '@/components/drawer/DrawerWithAsyncContent'
import ServiceCloudContent from '@/features/serviceactivity/CommercialServiceCloudSADetails.tsx'
import serviceActivityDrawerErrorText from '@/features/serviceactivity/detailsDrawer/serviceActivityDrawerErrorText.ts'

type Props = Readonly<{
  serviceActivityId: string
  assetId: number
  subject?: string | null
  summary?: string | null
  workOrdersCount?: number | null
  onClose: () => void
}>

export default function CommercialServiceActivityDrawer({
  serviceActivityId,
  assetId,
  onClose,
}: Props) {
  const query = useAssetCaseQuery(assetId, serviceActivityId)

  return (
    <DrawerWithAsyncContent
      errorText={serviceActivityDrawerErrorText}
      onClose={onClose}
      isPending={query.isPending}
      isError={query.isError}
    >
      {query.isSuccess && <ServiceCloudContent caseData={query.data} />}
    </DrawerWithAsyncContent>
  )
}

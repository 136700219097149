import React from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import SpinnerSvg from '@/assets/icons/spinner.svg?react'

interface SpinnerProps {
  className?: string
  width?: number
  height?: number
}

export default function Spinner({
  className,
  width = 16,
  height = 16,
}: Readonly<SpinnerProps>): React.JSX.Element {
  return (
    <SpinnerSvg
      data-testid="spinner"
      className={twMerge(className, 'tw-animate-spin')}
      width={width}
      height={height}
    />
  )
}

import { createContext } from 'react'

/**
 * Context that provides a facade around Okta Auth's state and simplifies mocking for tests/stories.
 */
export type AuthContextValue = {
  isAuthenticated?: boolean
  authError?: Error
  accessToken?: string
  userName?: string
  userEmail?: string
  signInWithRedirect: () => Promise<void>
  handleLoginRedirect: () => Promise<void>
  signOut: () => Promise<boolean>
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthContext = createContext<AuthContextValue>(null!)

import { useMemo } from 'react'
import { CommercialServiceActivity, PageName } from '@/generated'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { CsvColumnOption } from '@/components/export/TableDataExportCsvContextProvider.tsx'
import {
  toExcelCsvDateFormat,
  toExcelCsvDateTimeFormat,
} from '@/helpers/MomentHelpers.ts'

export default function useCommercialServiceActivityCsvColumns(
  pageName: PageName
) {
  const clientHasMultipleAssetManagers = useHasMultipleAssetManagers()
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()

  // The following is a mapping of the columns to include in the CSV export.
  // key is the field in the JSON data and header is the value that gets output
  // in the CSV header row that the end user sees.
  return useMemo<CsvColumnOption<CommercialServiceActivity>[]>(() => {
    const columns: CsvColumnOption<CommercialServiceActivity>[] = []
    if (pageName !== PageName.Asset) {
      if (clientHasMultipleAssetManagers) {
        columns.push({ key: 'assetManagerName', header: 'Asset Manager' })
      }
      columns.push({ key: 'assetName', header: 'Asset Name' })
    }
    if (serviceCloudIntegrationEnabled) {
      columns.push(
        { key: 'caseNumber', header: 'Case Number' },
        { key: 'workOrdersCount', header: 'Work Orders' }
      )
    } else {
      columns.push({ key: 'id', header: 'Ticket ID' })
    }
    columns.push(
      {
        key: 'createdAt',
        header: 'Creation Date',
        accessorFn: r => toExcelCsvDateTimeFormat(r.createdAt),
      },
      { key: 'priority', header: 'Priority' },
      { key: 'subject', header: 'Subject' },
      { key: 'creationReason', header: 'Creation Reason' },
      { key: 'status', header: 'Status' }
    )
    if (!serviceCloudIntegrationEnabled) {
      columns.push(
        { key: 'subStatus', header: 'Progress' },
        {
          key: 'scheduleDate',
          header: 'Schedule Date',
          accessorFn: r =>
            r.scheduleDate && toExcelCsvDateFormat(r.scheduleDate),
        }
      )
    }
    columns.push(
      {
        key: 'updatedAt',
        header: 'Last Updated',
        accessorFn: r => toExcelCsvDateTimeFormat(r.updatedAt),
      },
      { key: 'summary', header: 'Case Summary' }
    )
    if (serviceCloudIntegrationEnabled) {
      if (pageName === PageName.Asset) {
        columns.push({
          key: 'closedAt',
          header: 'Closed Date',
          accessorFn: r => r.closedAt && toExcelCsvDateTimeFormat(r.closedAt),
        })
      }
      columns.push({
        key: 'pendingClientAction',
        header: 'Pending Client Action',
        accessorFn: val => (val.pendingClientAction ? 'YES' : 'NO'),
      })
    }
    return columns
  }, [pageName, clientHasMultipleAssetManagers, serviceCloudIntegrationEnabled])
}

import { BaseServiceActivity, PageName } from '@/generated'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import useServiceActivityExportClient from '@/api/useServiceActivityExportClient.ts'
import { TableModel } from '@/components/core/table/useTable.tsx'
import { ExportCsvOptions } from '@/components/export/ExportCsvOptions.ts'
import ToastingExportCsvContextProvider from '@/components/export/ToastingExportCsvContextProvider.tsx'
import { PropsWithChildren, useCallback } from 'react'
import TableDataExportCsvContextProvider from '@/components/export/TableDataExportCsvContextProvider.tsx'
import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'

interface ServiceActivityCsvContextProviderProps<T> extends PropsWithChildren {
  pageName: PageName
  tableModel: TableModel<T>
  csvOptions?: ExportCsvOptions<T>
}

export default function ServiceActivityCsvContextProvider<
  T extends BaseServiceActivity,
>({
  pageName,
  tableModel,
  csvOptions,
  children,
}: Readonly<ServiceActivityCsvContextProviderProps<T>>) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  const serviceActivityExportClient = useServiceActivityExportClient()
  const clientProfileId = useClientProfileId()
  const csvDataFn = useCallback(async () => {
    return (
      await serviceActivityExportClient.downloadServiceActivityCsv(
        {
          caseIds: tableModel.getSortedRowModel().rows.map(r => r.original.id),
          exportZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
          pageName,
        },
        clientProfileId
      )
    ).data
  }, [serviceActivityExportClient, tableModel, pageName, clientProfileId])

  if (serviceCloudIntegrationEnabled) {
    return (
      <ToastingExportCsvContextProvider
        csvDataFn={csvDataFn}
        fileName={csvOptions?.fileName}
      >
        {children}
      </ToastingExportCsvContextProvider>
    )
  }
  return (
    <TableDataExportCsvContextProvider
      tableModel={tableModel}
      csvOptions={csvOptions}
    >
      {children}
    </TableDataExportCsvContextProvider>
  )
}

import { Id, toast } from 'react-toastify'
import ToastAlert, {
  ToastAlertProps,
} from '@/components/core/toast/ToastAlert.tsx'
import iconError from '@iconify/icons-material-symbols/error'
import iconInfo from '@iconify/icons-material-symbols/info'
import iconWarn from '@iconify/icons-material-symbols/warning'
import iconSuccess from '@iconify/icons-material-symbols/check'

export type ToasterOptions = Omit<ToastAlertProps, 'iconOptions' | 'closeToast'>
export type ToasterPromiseOptions = {
  promise: Promise<unknown>
  minToastMs?: number
  pendingToast: ToasterOptions
  errorToast: ToasterOptions
  successToast?: ToasterOptions
}

function showToast(props: Omit<ToastAlertProps, 'closeToast'>): Id {
  return toast(
    ({ closeToast }) => <ToastAlert {...props} closeToast={closeToast} />,
    {
      closeButton: false,
      hideProgressBar: true,
    }
  )
}

const showInfoToast = (opts: ToasterOptions): Id => {
  return showToast({
    ...opts,
    iconOptions: {
      icon: iconInfo,
      color: 'blue',
      bgColor: 'blue',
    },
  })
}
const showWarningToast = (opts: ToasterOptions) => {
  return showToast({
    ...opts,
    iconOptions: {
      icon: iconWarn,
      color: 'yellow',
      bgColor: 'yellow',
    },
  })
}
const showSuccessToast = (opts: ToasterOptions) => {
  return showToast({
    ...opts,
    iconOptions: {
      icon: iconSuccess,
      color: 'green',
      bgColor: 'green',
    },
  })
}
const showErrorToast = (opts: ToasterOptions) => {
  return showToast({
    ...opts,
    iconOptions: {
      icon: iconError,
      color: 'red',
      bgColor: 'red',
    },
  })
}
/**
 * Helper wrapper for interacting with react-toastify
 */
export const Toaster = {
  info: showInfoToast,
  warn: showWarningToast,
  success: showSuccessToast,
  error: showErrorToast,
  promise: async (opts: ToasterPromiseOptions): Promise<unknown> => {
    const pendingToastId = showInfoToast(opts.pendingToast)
    // Ensure the loading toast stays open a minimum amount so it doesn't slide in and immediately out.
    let canDismissToast = false
    const attemptToDismissPendingToast = () => {
      if (canDismissToast) {
        toast.dismiss(pendingToastId)
      } else {
        canDismissToast = true
      }
    }
    setTimeout(attemptToDismissPendingToast, opts.minToastMs ?? 2000)
    return opts.promise
      .then(() => {
        if (opts.successToast) showSuccessToast(opts.successToast)
      })
      .catch(() => showErrorToast(opts.errorToast))
      .finally(attemptToDismissPendingToast)
  },
}

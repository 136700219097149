import { useMemo, useState } from 'react'
import { Row } from '@tanstack/react-table'
import { CommercialServiceActivity, PageName } from '@/generated'
import useTable from '@/components/core/table/useTable.tsx'
import { useCommercialAssetServiceActivityQuery } from '@/api/AssetQueries.ts'
import useCommercialServiceActivityCsvColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityCsvColumns.ts'
import useCommercialServiceActivityTableColumns from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableColumns.tsx'
import useCommercialServiceActivityTableFilters from '@/pages/serviceActivity/commercial/useCommercialServiceActivityTableFilters.tsx'

import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import assetServiceActivitySort from '@/pages/assetdetail/serviceActivity/assetServiceActivitySort.ts'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import ServiceActivityCsvContextProvider from '@/pages/serviceActivity/ServiceActivityCsvContextProvider.tsx'
import { datedCsvFileName } from '@/helpers/ExportCsvHelpers.ts'
import CommercialServiceActivityDrawer from '@/features/serviceactivity/detailsDrawer/CommercialServiceActivityDrawer.tsx'
import { Portal } from '@headlessui/react'
import TicketSummaryModal from '@/components/modals/ticket-summary-modal/TicketSummaryModal.tsx'

export default function CommercialAssetServiceActivityTab(): React.JSX.Element {
  const { assetMetadata: data } = useAssetDetailContext()
  const serviceActivityQuery = useCommercialAssetServiceActivityQuery(
    data.assetId,
    true
  )

  const [selectedServiceActivity, setSelectedServiceActivity] =
    useState<CommercialServiceActivity>()

  const columns = useCommercialServiceActivityTableColumns(PageName.Asset)

  const exportCsvColumns = useCommercialServiceActivityCsvColumns(
    PageName.Asset
  )

  const filterDefs = useCommercialServiceActivityTableFilters(PageName.Asset)

  const rows = serviceActivityQuery.data?.data
  const sortedRows = useMemo<CommercialServiceActivity[] | undefined>(
    () => rows?.sort(assetServiceActivitySort),
    [rows]
  )
  const tableModel = useTable(sortedRows, columns, {
    pageSize: ServiceActivityPageSize,
  })

  const handleClickRow = (row: Row<CommercialServiceActivity>) => {
    setSelectedServiceActivity(row.original)
  }

  const clearSelectedServiceActivity = () => {
    setSelectedServiceActivity(undefined)
  }
  const csvOptions = useMemo(() => {
    return {
      columns: exportCsvColumns,
      fileName: datedCsvFileName(`${data.assetName}_service_activity`),
    }
  }, [exportCsvColumns, data])

  return (
    <>
      <h2 className="tw-mb-6 tw-text-xl tw-font-bold tw-text-gray-900">
        Service Activity
      </h2>
      <ServiceActivityCsvContextProvider
        pageName={PageName.Asset}
        tableModel={tableModel}
        csvOptions={csvOptions}
      >
        <ServiceActivityTable
          tableModel={tableModel}
          isLoading={serviceActivityQuery.isLoading}
          isError={serviceActivityQuery.isError}
          filterDefs={filterDefs}
          handleClickRow={handleClickRow}
        />
      </ServiceActivityCsvContextProvider>

      {selectedServiceActivity &&
        !!selectedServiceActivity.assetId &&
        (selectedServiceActivity.workOrdersCount !== null ? (
          <CommercialServiceActivityDrawer
            {...selectedServiceActivity}
            serviceActivityId={selectedServiceActivity.id}
            assetId={selectedServiceActivity.assetId}
            onClose={clearSelectedServiceActivity}
          />
        ) : (
          <Portal>
            <TicketSummaryModal
              show={true}
              serviceActivity={selectedServiceActivity}
              onClose={clearSelectedServiceActivity}
            />
          </Portal>
        ))}
    </>
  )
}

import { useContext, useMemo } from 'react'
import { AuthContext } from '@/contexts/auth/AuthContext.ts'

export type Auth = {
  isAuthenticated: boolean
  accessToken?: string
  signOut: () => Promise<boolean>
}

export function useAuth(): Auth {
  const authContext = useContext(AuthContext)

  return useMemo(
    () => ({
      isAuthenticated: authContext.isAuthenticated === true,
      accessToken: authContext.accessToken,
      signOut: authContext.signOut,
    }),
    [authContext]
  )
}

export type User = {
  name?: string
  email?: string
}

export function useUser(): User {
  const authContext = useContext(AuthContext)

  return {
    name: authContext.userName,
    email: authContext.userEmail,
  }
}

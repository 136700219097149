import { Configuration, ServiceActivityExportControllerApi } from '@/generated'
import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'
import { useMemo } from 'react'

export default function useServiceActivityExportClient(): ServiceActivityExportControllerApi {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new ServiceActivityExportControllerApi(new Configuration(config))
  }, [config])
}

import { createContext } from 'react'

export type ClientProfileContextValue = {
  activeClientProfileId?: string
  setActiveClientProfileId: (clientProfileId?: string) => void
}

export const ClientProfileContext = createContext<ClientProfileContextValue>({
  setActiveClientProfileId: () => {},
})

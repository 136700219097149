import { useFeatureFlagQuery } from '@/api/SpaQueries.ts'
import { LoadingPage } from '@/pages/LoadingPage.tsx'
import { Navigate, Route } from 'react-router-dom'
import { RequireFeature } from '@/components/feature-flags/RequireFeature.tsx'
import NotFoundPage from '@/pages/NotFoundPage.tsx'
import AssetDetailPageWrapper from '@/pages/assetdetail/AssetDetailPageWrapper.tsx'
import AssetServiceActivityTabWrapper from '@/pages/assetdetail/serviceActivity/AssetServiceActivityTabWrapper.tsx'
import AssetDetailsTabWrapper from '@/pages/assetdetail/tabComponents/AssetDetailsTabWrapper.tsx'

export const AssetDetailRoute = '/assets/:assetId'
export const AssetDetailsTabRoute = 'details'
export const AssetServiceActivityTabRoute = 'service-activity'

export const AssetDetailRoutes: React.JSX.Element = (
  <Route
    path={AssetDetailRoute}
    element={
      <RequireFeature feature={'assetDetailEnabled'} denied={<NotFoundPage />}>
        <AssetDetailPageWrapper />
      </RequireFeature>
    }
  >
    <Route index element={<AssetDefaultRoute />} />
    <Route path={AssetDetailsTabRoute} element={<AssetDetailsTabWrapper />} />
    <Route
      path={AssetServiceActivityTabRoute}
      element={<AssetServiceActivityTabWrapper />}
    />
  </Route>
)

export function AssetDefaultRoute() {
  const featureFlagsQuery = useFeatureFlagQuery()
  if (featureFlagsQuery.isLoading) {
    return <LoadingPage />
  }

  return <Navigate replace to={AssetDetailsTabRoute} />
}

import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { AuthContext } from '@/contexts/auth/AuthContext.ts'

interface AuthenticatedProps extends PropsWithChildren {
  readonly redirectingMessage?: React.ReactNode
}

export function Authenticated(props: AuthenticatedProps): React.JSX.Element {
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (authContext.isAuthenticated === false) {
      void authContext.signInWithRedirect()
    }
  }, [authContext])

  if (authContext.isAuthenticated) return <>{props.children}</>

  return <>{props.redirectingMessage}</>
}

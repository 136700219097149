import { WorkOrder } from '@/generated'
import { createContext, useContext } from 'react'

export interface IWorkOrdersContext {
  workOrders: WorkOrder[]
  selected: WorkOrder
  onSelect: (woId: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const WorkOrdersContext = createContext<IWorkOrdersContext>(null!)

export const useWorkOrdersContext = () => {
  return useContext(WorkOrdersContext)
}

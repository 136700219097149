import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import {
  ExportCsvContext,
  ExportCsvContextValue,
  ExportCsvState,
} from '@/components/export/ExportCsvContext.ts'
import saveAs from 'file-saver'
import { Toaster } from '@/components/core/toast/Toaster.tsx'

export interface ToastingExportCsvContextProviderProps
  extends PropsWithChildren {
  csvDataFn: () => Promise<string>
  fileName?: string
}

export default function ToastingExportCsvContextProvider({
  csvDataFn,
  fileName,
  children,
}: Readonly<ToastingExportCsvContextProviderProps>) {
  const [exporting, setExporting] = useState<boolean>(false)
  const downloadCsv = useCallback(async () => {
    if (!fileName) {
      throw new Error(
        'Export triggered before fileNamePrefix was provided. This should not be allowed.'
      )
    }
    setExporting(true)
    try {
      const csv = await csvDataFn()
      const blob = new Blob([csv], { type: 'text/x-csv' })
      saveAs(blob, fileName)
    } finally {
      setExporting(false)
    }
  }, [fileName, csvDataFn])
  const exportState = useMemo<ExportCsvState>(() => {
    if (exporting) return 'exporting'
    if (!fileName) return 'initializing'
    return 'ready'
  }, [exporting, fileName])
  const triggerExport = useCallback(() => {
    void Toaster.promise({
      promise: downloadCsv(),
      pendingToast: {
        title: 'Preparing your download',
        message: 'This may take a few seconds',
      },
      errorToast: {
        title: 'Export failed',
        message: "We couldn't generate your CSV. Please try again.",
      },
    })
  }, [downloadCsv])
  const contextValue = useMemo<ExportCsvContextValue>(
    () => ({
      state: exportState,
      triggerExport,
    }),
    [exportState, triggerExport]
  )
  return (
    <ExportCsvContext.Provider value={contextValue}>
      {children}
    </ExportCsvContext.Provider>
  )
}

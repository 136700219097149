import { Maybe } from '@/helpers/TypeHelpers.ts'

type Item = {
  label: string
  value: Maybe<string>
}

type Props = {
  items: Array<Item>
}
export default function InstallationDetails({ items }: Readonly<Props>) {
  return (
    <div>
      <div className="tw-mb-6 tw-text-xl tw-font-medium tw-text-gray-900">
        Installation Details
      </div>
      {items.map(item => (
        <div key={item.label}>{renderKeyValue(item.label, item.value)}</div>
      ))}
    </div>
  )
}

function renderKeyValue(key: string, value: Maybe<string>) {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-py-2">
      <span className="tw-font-medium tw-text-gray-900">{key}</span>
      <span className="tw-font-normal tw-text-gray-500">{value ?? ''}</span>
    </div>
  )
}

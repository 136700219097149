import { createContext, useContext } from 'react'
import { AssetMetadata } from '@/generated'

export type AssetDetailContextValue = {
  assetMetadata: AssetMetadata
}
export const AssetDetailContext = createContext<AssetDetailContextValue | null>(
  null
)
export function useAssetDetailContext(): AssetDetailContextValue {
  const context = useContext(AssetDetailContext)
  if (!context) {
    throw new Error('No AssetDetailContext available')
  }
  return context
}

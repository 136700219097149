import { Priority, BaseServiceActivity } from '@/generated'
import moment from 'moment'
import { BadgeColor } from '@/components/core/badges/BadgeColor.ts'

export const priorityToBadgeColor = (
  priority: Priority | null
): keyof typeof BadgeColor => {
  if (priority === Priority.Urgent) {
    return 'red'
  } else if (priority === Priority.High) {
    return 'yellow'
  }

  return 'gray'
}

export const statusToBadgeColor = (
  status: string,
  pendingClientAction: boolean = false
): keyof typeof BadgeColor => {
  if (pendingClientAction) {
    return 'red'
  }
  if (closedStatuses.has(status)) {
    return 'green'
  }
  return 'blue'
}

export const calcOpenDays = (createdAt: string, closedAt?: string): number => {
  return moment(closedAt).diff(moment(createdAt), 'days')
}

const statusSortMap = new Map<string | null, number>([
  ['New', 0],
  ['In Progress', 1],
  ['Work In Progress', 2],
  ['Open', 3],
  ['Pending', 4],
  ['Pending Client Approval', 5],
  ['Hold', 6],
  ['On Hold', 7],
  ['Merged', 8],
  ['Solved', 9],
  ['Completed', 10],
  ['Closed', 11],
])
const closedStatuses: Set<string | null> = new Set([
  'Solved',
  'Completed',
  'Closed',
  'Merged',
])

export const isServiceActivityClosed = (
  serviceActivity: BaseServiceActivity
) => {
  return closedStatuses.has(serviceActivity.status)
}

export function serviceActivityStatusSort(
  statusA: string | null,
  statusB: string | null
) {
  const p1 = statusSortMap.get(statusA) ?? 1_000_000
  const p2 = statusSortMap.get(statusB) ?? 1_000_000
  if (p1 < p2) return -1
  if (p1 > p2) return 1
  return 0
}

import React, {
  ComponentProps,
  MutableRefObject,
  PropsWithChildren,
  useRef,
} from 'react'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { Icon } from '@iconify/react/offline'
import iconClose from '@iconify/icons-heroicons/x-mark'

const DrawerSize = {
  small: 'tw-w-[384px]',
  large: 'tw-w-[1106px]',
}

interface DrawerProps extends PropsWithChildren<ComponentProps<'div'>> {
  show: boolean
  initialFocus?: MutableRefObject<HTMLElement | null>
  onClose: () => void
  size?: keyof typeof DrawerSize
}

interface DrawerHeaderProps extends PropsWithChildren<ComponentProps<'div'>> {
  readonly onClose: () => void
}

type DrawerBodyProps = PropsWithChildren<ComponentProps<'div'>>

type DrawerFooterProps = PropsWithChildren<ComponentProps<'div'>>

function DrawerHeader({
  className,
  onClose,
  children,
  ...props
}: DrawerHeaderProps) {
  return (
    <DialogTitle
      as="div"
      className="tw-flex tw-h-drawer-header tw-items-center tw-self-stretch tw-p-4"
    >
      <h5
        className={twMerge(`tw-uppercase tw-text-slate-500`, className)}
        {...props}
      >
        {children}
      </h5>
      <button
        type="button"
        className="tw--m-1.5 tw-ml-auto tw-inline-flex tw-size-8 tw-items-center tw-justify-center tw-rounded-lg tw-bg-transparent tw-text-gray-600 hover:tw-bg-gray-200 hover:tw-text-gray-900 focus:tw-ring-2 focus:tw-ring-gray-400"
        aria-label="Close"
        onClick={onClose}
      >
        <span className="tw-sr-only">Close</span>
        <Icon icon={iconClose} className="tw-size-5" aria-hidden="true" />
      </button>
    </DialogTitle>
  )
}

function DrawerBody({ className, children, ...props }: DrawerBodyProps) {
  return (
    <div
      className={twMerge(
        `tw-h-drawer-content-with-footer tw-overflow-y-auto tw-px-4 last:tw-h-drawer-content`,
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

function DrawerFooter({ className, children, ...props }: DrawerFooterProps) {
  return (
    <div
      className={twMerge(
        'tw-flex tw-h-drawer-footer tw-gap-4 tw-p-4',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export function Drawer({
  show,
  className,
  initialFocus,
  onClose,
  size = 'small',
  children,
  ...props
}: Readonly<DrawerProps>): React.JSX.Element {
  const panelRef = useRef<HTMLDivElement>(null)
  return (
    <Transition as="div" show={show}>
      <Dialog initialFocus={initialFocus} onClose={onClose}>
        <div className="tw-fixed tw-inset-0 tw-z-overlay tw-bg-gray-500/75 tw-transition-opacity" />
        <DialogPanel
          as="div"
          className="tw-fixed tw-right-0 tw-top-0 tw-z-modal"
        >
          <TransitionChild
            as="div"
            enter="tw-transition tw-duration-150 tw-ease-out"
            enterFrom="tw-translate-x-full"
            enterTo="tw-transform-none"
            leave="tw-transition tw-duration-150 tw-ease-in"
            leaveFrom="tw-transform-none"
            leaveTo="tw-translate-x-full"
          >
            <div
              ref={panelRef}
              tabIndex={-1}
              className={twMerge(
                `tw-h-screen tw-bg-white tw-transition-transform`,
                DrawerSize[size],
                className
              )}
              {...props}
            >
              {children}
            </div>
          </TransitionChild>
        </DialogPanel>
      </Dialog>
    </Transition>
  )
}

Drawer.Header = DrawerHeader
Drawer.Body = DrawerBody
Drawer.Footer = DrawerFooter

DrawerHeader.displayName = 'Drawer.Header'
DrawerBody.displayName = 'Drawer.Body'
DrawerFooter.displayName = 'Drawer.Footer'

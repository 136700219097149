import { useParams } from 'react-router-dom'
import NotFoundPage from '@/pages/NotFoundPage.tsx'
import AssetDetailPage from '@/pages/assetdetail/AssetDetailPage.tsx'
import { AssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { useMemo } from 'react'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'
import { AssetMetadata } from '@/generated'

export type AssetDetailParams = {
  assetId?: string
}

export default function AssetDetailPageWrapper() {
  const params = useParams<AssetDetailParams>()
  const assetId = parseInt(params.assetId ?? '', 10)
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  const isError = assetMetadataQuery.isError
  const assetDetailContext = useMemo(
    () => ({
      assetMetadata: assetMetadataQuery.data,
    }),
    [assetMetadataQuery.data]
  )
  if (isNaN(assetId)) {
    return <NotFoundPage />
  }
  if (!assetDetailContext.assetMetadata) {
    return null
  }
  if (isError) {
    // This will be handled by the global error handler modal. Could use some more nuance here
    // (e.g. identifying a 404 vs. a 500) to render a dedicated UI.
    // https://omnidian.atlassian.net/browse/HYP-2510 for finding a more holistic solution for exposing the status
    // code from the error and develop a holistic solution that could be applied to any page.
    throw assetMetadataQuery.error
  }
  return (
    <AssetDetailContext.Provider
      value={assetDetailContext as { assetMetadata: AssetMetadata }}
    >
      <AssetDetailPage />
    </AssetDetailContext.Provider>
  )
}

import { Icon, IconifyIcon } from '@iconify/react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { ToastContentProps } from 'react-toastify'
import iconClose from '@iconify/icons-heroicons/x-mark-20-solid'

const IconColor = {
  red: 'tw-text-red-600',
  yellow: 'tw-text-yellow-600',
  green: 'tw-text-green-600',
  blue: 'tw-text-blue-600',
}

const IconBackgroundColor = {
  red: 'tw-bg-red-100',
  yellow: 'tw-bg-yellow-100',
  green: 'tw-bg-green-100',
  blue: 'tw-bg-blue-100',
}

export interface ToastAlertProps extends Pick<ToastContentProps, 'closeToast'> {
  title?: string
  message: string
  iconOptions?: {
    icon: IconifyIcon
    color?: keyof typeof IconColor
    bgColor?: keyof typeof IconBackgroundColor
  }
}

export default function ToastAlert({
  title,
  iconOptions,
  closeToast,
  message,
}: Readonly<ToastAlertProps>) {
  return (
    <div className="tw-inline-flex tw-w-full tw-justify-between tw-gap-4 tw-p-0.5">
      {iconOptions && (
        <div
          className={twMerge(
            'tw-size-8 tw-rounded-lg tw-p-1.5',
            iconOptions.bgColor
              ? IconBackgroundColor[iconOptions.bgColor]
              : null
          )}
        >
          <Icon
            icon={iconOptions.icon}
            className={twMerge(
              'tw-size-5',
              iconOptions.color ? IconColor[iconOptions.color] : null
            )}
          />
        </div>
      )}
      <div className="tw-flex tw-grow tw-flex-col tw-text-sm">
        {title && (
          <div className="tw-mb-2 tw-text-sm tw-font-bold tw-text-gray-900">
            {title}
          </div>
        )}
        {message}
      </div>
      <div>
        <button
          className="-tw-m-1.5 tw-ml-auto tw-inline-flex tw-h-8 tw-w-8 tw-rounded-lg tw-border-0 tw-bg-transparent tw-p-1.5 focus:tw-ring-2"
          onClick={closeToast}
          aria-label="Close"
        >
          <span className="tw-sr-only">Close</span>
          <Icon icon={iconClose} className="tw-size-5" />
        </button>
      </div>
    </div>
  )
}

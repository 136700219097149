import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { AssetMetadata } from '@/generated'
import { formatNumberKw } from '@/helpers/NumberFormats.ts'
import InstallationDetails from '@/pages/assetdetail/tabComponents/InstallationDetails.tsx'

export default function ResidentialAssetDetailsTab() {
  const { assetMetadata: data } = useAssetDetailContext()
  return (
    <div>
      <div className="tw-flex tw-gap-8">
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-8 tw-p-6 tw-px-4">
          {renderCustomerInfo(data)}
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-8 tw-p-6 tw-px-4">
          {renderInstallationDetails(data)}
        </div>
      </div>
    </div>
  )
}

function renderCustomerInfo({
  homeOwner,
  clientAssetId,
  assetId,
}: AssetMetadata) {
  return (
    <div className="tw-border-b tw-text-gray-700">
      <div className="tw-mb-6">
        <h3 className="tw-mb-6 tw-text-xl tw-font-medium tw-text-gray-900">
          Customer Contact
        </h3>
        <p className="tw-mb-1">{homeOwner.name}</p>
        <p className="tw-mb-1">{homeOwner.phone ?? ''}</p>
        <p>{homeOwner.email ?? ''}</p>
      </div>
      <div className="tw-mb-8">
        <p className="tw-mb-1">Client Asset ID: {clientAssetId}</p>
        <p>Omnidian Asset ID: {assetId}</p>
      </div>
    </div>
  )
}

function renderInstallationDetails({
  installerName,
  ptoDate,
  systemSize,
}: AssetMetadata) {
  return (
    <div>
      <InstallationDetails
        items={[
          { label: 'System Size', value: formatNumberKw(systemSize) },
          { label: 'Original installer', value: installerName },
          { label: 'PTO date', value: ptoDate },
        ]}
      />
    </div>
  )
}

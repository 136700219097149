import { useMemo } from 'react'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import WorkOrderTab from './WorkOrderTab.tsx'
import DetailsTabPanel from './panels/DetailsTabPanel.tsx'

export default function WorkOrderTabGroup() {
  const tabs = useMemo(
    () => [
      {
        title: 'Details',
        panel: <DetailsTabPanel />,
      },
    ],
    []
  )

  return (
    <TabGroup>
      <TabList className="tw-border-b tw-border-gray-200">
        {tabs.map(({ title }) => (
          <Tab
            key={title}
            className="tw--mb-px tw-mr-2 tw-outline-none last:tw-mr-0"
          >
            {({ selected }) => (
              <WorkOrderTab selected={selected}>{title}</WorkOrderTab>
            )}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {tabs.map(({ title, panel }) => (
          <TabPanel key={title}>{panel}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  )
}

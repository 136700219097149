import { Modal } from '@/components/core/modals/Modal.tsx'
import Button from '@/components/core/buttons/Button.tsx'
import type { BaseServiceActivity } from '@/generated'
import ServiceActivitySummary from '@/features/serviceactivity/ServiceActivitySummary.tsx'

interface TicketSummaryModalProps {
  show: boolean
  serviceActivity: BaseServiceActivity
  onClose: () => void
}

export default function TicketSummaryModal({
  show,
  serviceActivity,
  onClose,
}: Readonly<TicketSummaryModalProps>) {
  return (
    <div className="tw-z-modal">
      <Modal show={show} onClose={onClose}>
        <Modal.Header onClose={onClose}>
          {serviceActivity.subject} Case Summary
        </Modal.Header>
        <Modal.Body>
          <ServiceActivitySummary summary={serviceActivity.summary} />
        </Modal.Body>
        <Modal.Footer className="tw-flex tw-flex-row tw-justify-end">
          <Button onClick={onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { formatNumberKw } from '@/helpers/NumberFormats.ts'
import InstallationDetails from '@/pages/assetdetail/tabComponents/InstallationDetails.tsx'

export default function CommercialAssetDetailsTab() {
  const { assetMetadata: data } = useAssetDetailContext()
  return (
    <div>
      <div className="tw-flex tw-gap-8">
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-8 tw-p-6 tw-px-4">
          {renderCustomerInfo()}
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-8 tw-p-6 tw-px-4">
          <InstallationDetails
            items={[
              {
                label: 'System Size',
                value: formatNumberKw(data.systemSize),
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}

function renderCustomerInfo() {
  return (
    <div className="tw-border-b tw-text-gray-700">
      <h3 className="tw-mb-6 tw-text-xl tw-font-medium tw-text-gray-900">
        Contacts
      </h3>
      <div className="tw-rounded tw-bg-gray-50 tw-p-8">
        <div className="tw-mb-2 tw-text-base tw-font-semibold">
          Additional site details coming soon...
        </div>
        <div className="tw-text-sm tw-font-normal">
          You can expect to see more information about each asset on this page,
          coming soon!
        </div>
      </div>
    </div>
  )
}

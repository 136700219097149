import downloadIcon from '@iconify/icons-material-symbols/download'
import React from 'react'
import Button from '@/components/core/buttons/Button.tsx'
import { useExportCsvContext } from '@/components/export/ExportCsvContext.ts'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import Spinner from '@/components/core/spinner/Spinner.tsx'

interface ExportCsvButtonProps {
  tooltipMessage?: string
}

export function ExportCsvButton({
  tooltipMessage,
}: Readonly<ExportCsvButtonProps>): React.JSX.Element {
  const { state, triggerExport } = useExportCsvContext()

  if (state === 'initializing') return <></>

  if (tooltipMessage && state !== 'exporting') {
    return (
      <Tooltip content={tooltipMessage}>
        <ExportButton onClick={triggerExport} />
      </Tooltip>
    )
  }
  return <ExportButton onClick={triggerExport} />
}

interface ExportButtonProps {
  onClick: () => void
}

function ExportButton({ onClick }: Readonly<ExportButtonProps>) {
  const { state } = useExportCsvContext()
  return (
    <Button
      size="sm"
      color="light"
      icon={state === 'exporting' ? undefined : downloadIcon}
      onClick={onClick}
    >
      {state === 'exporting' ? (
        <div className="tw-space-x-1">
          <Spinner className="tw-inline" />
          <span>Exporting...</span>
        </div>
      ) : (
        'Export'
      )}
    </Button>
  )
}
